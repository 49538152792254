


*, *::before, *::after {
  box-sizing: border-box;
}


  html,
  body {
    margin: 0px;
    height: 100%;
    width: 100%;
  }

  .mainDIv {
    background: #09121A;
}

html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make app-root take full viewport height */
  }
  main {
    flex: 1; /* Pushes the footer down by taking up remaining space */
  }
  .space-provider{
   
  }
